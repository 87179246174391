import React from "react";
import { GiGraduateCap } from "react-icons/gi";
import { TfiWorld } from "react-icons/tfi";
import myImage from "../../assets/my_2.png";
import "./about.css";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        {/* <div className="about__me">
          <div className="about__me-image">
            <img src={myImage} alt="Me" />
          </div>
        </div> */}

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <GiGraduateCap className="about__icon" />
              <h5>Degree</h5>
              <small>
                Bachelor in Computer Science <br />
                <i>National Univeristy of Computer and Emerging Sciences</i>
              </small>
            </article>

            <article className="about__card">
              <TfiWorld className="about__icon" />
              <h5>Domains</h5>
              <small>
                <ul>
                  <li>Websites</li>
                  <li>Mobile Applications</li>
                  <li>Web Applications</li>
                </ul>
              </small>
            </article>
          </div>
          <p>
            Hello, I'm Sinan Lakhani, a seasoned React Native Developer with
            over 4 years of experience. I ventured into freelancing during my
            last year of university. I specialize in crafting high-quality web
            and mobile applications using React and React Native. My academic
            background from FAST National University in 2020 complements my
            practical skills, ensuring a comprehensive approach to development.
            If you're in need of an experienced and proficient React Native
            Developer, I'm here for you.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
