import React from 'react'
import './myprojects.css'
import IMG1 from '../../assets/1.jpg'
import IMG2 from '../../assets/2.jpg'
import IMG3 from '../../assets/3.jpg'
import IMG4 from '../../assets/4.jpg'
import IMG5 from '../../assets/5.jpg'
import IMG6 from '../../assets/6.jpg'

const Myprojects = () => {
  return (
    <section id='myprojects'>

      <h5>My Recent Works</h5>
      <h2>Projects</h2>

      <div className="container portfolio__container">

        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG1} alt="" />
          </div>
          <h3>AmberStore</h3>
          <small className='text-light'>ReactJs | React-Native | NodeJs |MongoDB</small>
          <div className="portfolio__item-cta">
            <a style={{ fontSize: 14 }} href="https://play.google.com/store/apps/details?id=com.marketplace_app&hl=en&gl=US" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Android App</a>
            <a style={{ fontSize: 14 }} href="https://apps.apple.com/pk/app/amberstore/id1614418526" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit IOS App</a>
          </div>
          <div className="portfolio__item-cta">
            <a style={{ fontSize: 14, width: "100%", textAlign: "center" }} href="https://amberstore.pk" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Website</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG2} alt="" />
          </div>
          <h3>Investiera (Fund Transfer Application)</h3>

          <small className='text-light'>ReactJs | React-Native | NodeJs |MongoDB</small>
          <div className="portfolio__item-cta">
            <a style={{ fontSize: 14, width: "100%", textAlign: "center" }} href="https://testflight.apple.com/join/A09VauPE" target="_blank" rel='noreferrer' className='btn btn-primary'>Test Flight Link</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG4} alt="" />
          </div>
          <h3>Pet Shack</h3>
          <small className='text-light'>React-Native</small>
          <div className="portfolio__item-cta">
            <a style={{ fontSize: 14 }} href="https://play.google.com/store/apps/details?id=com.pet_shack" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Android App</a>
            <a style={{ fontSize: 14 }} href="https://play.google.com/store/apps/details?id=com.pet_shack" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit IOS App</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG3} alt="" />
          </div>
          <h3>Zannys Food</h3>
          <small className='text-light'>ReactJs | React-Native | NodeJs |MongoDB</small>
          <div className="portfolio__item-cta">
            <a style={{ fontSize: 14 }} href="https://play.google.com/store/apps/details?id=com.zanny_customer" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Android App</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG5} alt="" />
          </div>
          <h3>AmberStore(DropShipping)</h3>
          <small className='text-light'>ReactJs | React-Native | NodeJs |MongoDB</small>
          <div className="portfolio__item-cta">
            <a style={{ fontSize: 14 }} href="https://play.google.com/store/apps/details?id=com.dropshipping_amberstore" target="_blank" rel='noreferrer' className='btn btn-primary'>Visit Android App</a>
          </div>
        </article>

        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG6} alt="" />
          </div>
          <h3>Rushed 2 U (Food Delivery Application)</h3>
          <small className='text-light'>ReactJs | React-Native | NodeJs |MongoDB</small>
        </article>
      </div>
    </section>
  )
}

export default Myprojects